import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../Constants/index'
import { Link, Redirect } from 'react-router-dom'
// import EquipmentDeltail from "../Components/EquipmentDetail";
//import F0101T1 from "../01Pressure/F0101/F0101T1"

import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import cellButton from '../../Component/cellButton';
import cellButtonReport from '../../Component/CellButtonReport';
import CellBtnIcon from '../CellBtnIcon';
import UserDefaultCustomerText from '../../Component/Calibration/UserDefaultCustomerText';
import CellButtonResult from '../../Component/CellButtonResults';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import cellBtnTimesheetMonthlyItem from './Component/CellBtnTimesheetDailyItem'
import TimesheetMonthlyItemTable from './Component/TimesheetMonthlyItemTable'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import BreadcrumbsNav from '../../Navigation/BreadcrumbsNav.jsx';

const editField = "inEdit";

const TimesheetDailyItems = (props) => {
   
    const [entityRef, setEntityRef] = React.useState(props.match.params.entityRef);
    const [timesheetDaily, setTimesheetDaily] = React.useState('');    
    const [unitOfMeasurment, setUnitOfMeasurment] = React.useState('');    
    const PAGE_TITLE = "TimesheetMonthly Items";

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    React.useEffect(() => {
        //fetchTimesheetDaily();       
    }, []);

    const fetchTimesheetDaily = () => {       
        
        getIdToken(user).then((idToken) => {
            fetch(Config.API_URL + "TimesheetDaily/GetTimesheetDaily/?id=" + entityRef
                + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    //setCalibrationReport(json)
                    setTimesheetDaily(json);
                    debugger;
                });
             });
    }

    

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            <NavBar>
                <BreadcrumbsNav
                    lbl1="Timesheet Monthly"
                    lbl1path="/TimesheetMonthly"
                    lbl2="Items"
                />
            </NavBar>
        <div className="container-fluid py-4">


            <div className="col-12">
                <div className="card mb-4">
                    <div class="card-header p-3 pt-2">
                        <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                            <i class="fa fas fa-calendar-check fa-fw" aria-hidden="true"></i>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <h6 class="mb-0">
                                        {PAGE_TITLE}
                                </h6>
                            </div>

                        </div>
                    </div>


                    <div className="card-body px-0 pt-0 pb-2"> 
                        <TimesheetMonthlyItemTable 
                            timesheetMonthlyId={entityRef}
                            user={user}
                        />                
                    <div>
</div>

</div>
</div>
</div>




</div>

<Footer />
</main>

    );
};

export default TimesheetDailyItems;