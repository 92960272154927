import React from "react";
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import Axios from 'axios';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import HistoryList from "./HistoryList";
import { Redirect } from 'react-router-dom'
import moment from "moment";

const AcdCertHistory = (props) => {

  const visible = props.visible;
  const item = props.item;

  const [alertDialog, setAlertDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [loadingArea, setLoadingArea] = React.useState(false);
  const [isRedirecToEdit, setIsRedirecToEdit] = React.useState(false);

  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);





  const deleteHandler = () => {

    let messages = [];

    getIdToken(user).then((idToken) => {

      Axios.post(Config.API_URL + "AcdCert/delete",
        {
          Id: item.id,
        },
        {
          headers: {
            'Authorization': 'Bearer ' + idToken
          }
        }
      )
        .then(response => {
          setDeleteDialog(false);
          props.onDelete();


        })
        .catch(error => {
          if (error.response && error.response.status == 400) {
            error.response.data.message.map(p => {
              messages.push(p);
            })
          }
          else {
            messages.push("Failed to connect to server")
          }

          setAlertDialog(true);
          setMessages(messages);
        });
    });
  }

  const draftHandler = () => { changeStatus("draft") };
  const submitHandler = () => { changeStatus("submit") };
  const approveHandler = () => { changeStatus("approve") };
  const rejectHandler = () => { changeStatus("reject") };
  const archiveHandler = () => { changeStatus("archive") };

  const changeStatus = (statusTitle) => {
    setLoadingArea(true);
    let messages = [];

    getIdToken(user).then((idToken) => {

      Axios.post(Config.API_URL + "AcdCert/" + statusTitle,
        {
          id: item.id,
        },
        {
          headers: {
            'Authorization': 'Bearer ' + idToken
          }
        }
      )
        .then(response => {
          setDeleteDialog(false);
          props.onItemUpdate(response.data);
          setLoadingArea(false);
        })
        .catch(error => {
          if (error.response && error.response.status == 400) {
            error.response.data.message.map(p => {
              messages.push(p);
            })
          }
          else {
            messages.push("Failed to connect to server")
          }
          setLoadingArea(false);
          setAlertDialog(true);
          setMessages(messages);
        });
    });
  }

  if (!visible)
    return (<div></div>)

  if (isRedirecToEdit) {
    return <Redirect to={'/AcdCert/Edit/' + item.id} />
  }
  
  const getDifference = (date1, date2) => {
    let diff = Math.abs(date2 - date1);
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  return (
    <div className="col-12 col-lg-3">
      <div className="card mb-3 mt-lg-0 mt-4 card-plain">

        {loadingArea &&
          <div className="card-body pb-0">
            <i class='fa fa-spinner fa-spin '></i> Loading...
          </div>
        }

        {!loadingArea &&
          <div className="card-body pb-0">
            <div className="row align-items-center mb-3">
              <div className="col-9">
                <h5 className="mb-1 text-gradient text-primary">
                  <a href="javascript:;">{item.certificateNumber}</a>
                </h5>
              </div>
              {/* <div className="col-3 text-end">
          <div className="dropstart">
            <a href="javascript:;" className="text-secondary" id="dropdownMarketingCard" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="material-icons text-xl">more_vert</i>
            </a>
            <ul className="dropdown-menu dropdown-menu-lg-start px-2 py-3" aria-labelledby="dropdownMarketingCard">
              <li><a className="dropdown-item border-radius-md" href="javascript:;">Edit Team</a></li>
              <li><a className="dropdown-item border-radius-md" href="javascript:;">Add Member</a></li>
              <li><a className="dropdown-item border-radius-md" href="javascript:;">See Details</a></li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li><a className="dropdown-item border-radius-md text-danger" href="javascript:;">Remove Team</a></li>
            </ul>
          </div>
        </div> */}
            </div>
            <p>{item.vAcdCourseTitle}</p>
            <p class="mb-0"><b>{item.participantFullName}</b></p>
            <hr className="horizontal dark my-1" />
            <div className="d-flex">
              {/* <button className="btn bg-gradient-dark px-3 mb-2 active" data-class="bg-gradient-dark" onclick="sidebarType(this)">Dark</button> */}

              <a className="btn bg-gradient-dark px-3 mb-2 active" data-class="bg-gradient-dark" href={Config.API_URL
                + "report/AcdCert/?id=" + item.id + "&guid=" + item.reportGUID} target="_blank" >
                <span role="presentation" class="k-icon k-i-print"></span>
                {item.vStatusTitle == "Approved" || item.vStatusTitle == "Archive" ? " Print" : " Print Preview"}
              </a>

              {/* <button className="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-transparent" onclick="sidebarType(this)">
                Edit</button> */}

            </div>
            <hr className="horizontal dark my-1" />
            <div className="d-flex">

              {item.vStatusTitle == "Draft" &&
                <button className="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-transparent" onClick={() => setIsRedirecToEdit(true)}>
                  Edit</button>
              }
              {item.vStatusTitle == "Draft" &&
                <button className="btn bg-gradient-danger px-3 mb-2 ms-2" data-class="bg-white" onClick={() => { setDeleteDialog(true) }}>
                  Delete</button>
              }
            </div>

            <hr className="horizontal dark my-1" />



            <ul className="list-unstyled mx-auto">
              <li className="d-flex">
                <p className="mb-0">Status</p>
                <span className="badge badge-secondary ms-auto">{item.vStatusTitle}</span>
              </li>
              <li>
                <hr className="horizontal dark" />
              </li>
            </ul>

            <div className="d-flex">

              {/* //{item.vStatusTitle == "Draft" && 
            
            
            
            /*}
            
            */}

              {item.vStatusTitle == "Draft" &&
                <button className="btn bg-gradient-primary px-3 mb-2 ms-2" data-class="bg-white" onClick={submitHandler}>
                  Submit</button>}


              {item.vStatusTitle == "Submited" &&
                <button className="btn bg-gradient-success px-3 mb-2 ms-2" data-class="bg-white" onClick={approveHandler}>
                  Approve</button>}


              {item.vStatusTitle == "Submited" &&
                <button className="btn bg-gradient-danger px-3 mb-2 ms-2" data-class="bg-white" onClick={rejectHandler}>
                  Reject</button>
              }


              {item.vStatusTitle == "Rejected" &&
                <button className="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-transparent" onClick={draftHandler}>
                  Draft</button>
              }

              <br />
              <br />
              
              <br />





              {

                (item.vStatusTitle == "Approved"  ) &&
                <button className="btn bg-gradient-primary px-3 mb-2 ms-2" data-class="bg-white" onClick={archiveHandler}>
                  Archive</button>
              }

            </div>

            <HistoryList acdCert={item}></HistoryList>
          </div>}
      </div>



      {
        //Make Delete As Component
        deleteDialog &&
        <Dialog
          title="Delete"
          onClose={() => { setDeleteDialog(false) }}
        >
          <div>
            Are you sure you want to delete this item?
            {/* آیا برای حذف {deleteTitle} مطمئن هستید؟ */}
          </div>

          <DialogActionsBar>
            <button
              className="k-button k-primary"
              onClick={deleteHandler}
            >
              Yes
            </button>
            <button
              className="k-button"
              onClick={() => { setDeleteDialog(false) }}

            >
              No
            </button>
          </DialogActionsBar>
        </Dialog>
      }

      {
        alertDialog &&
        <Dialog
          title="Alert"
          onClose={() => { setAlertDialog(false) }} >

          {messages.length > 0 &&
            <ul>
              {messages.map((message) => {
                return (
                  <li>{message}</li>
                )
              })}
            </ul>
          }

          <DialogActionsBar>
            <button
              className="k-button k-primary"
              onClick={() => { setAlertDialog(false) }}
            >
              Close
            </button>
          </DialogActionsBar>
        </Dialog>
      }
    </div>

  )


}

export default AcdCertHistory;