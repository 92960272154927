const Config = {
    API_URL: process.env.REACT_APP_API_URL, 
    SITE_TITLE: "CITADEL BPM",
    SITE_PANEL_ICON: "far fa-building",
    APP_TITLE: "Control Panel ", 
    APP_BRAND: "Citadel IT Department",
    APP_BRAND_URL: "https://www.citadel-engineering.com/",

    UPLOAD_IMAGE1_WITDTH : 1024,
    UPLOAD_IMAGE1_HEIGHT : 768,
    UPLOAD_THUMB1_WITDTH : 300,
    UPLOAD_THUMB1_HEIGHT : 200,           
}


export default Config;