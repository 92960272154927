import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import Axios from 'axios';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import Alert from '../../Component/Alert';

const IsoStandard_Add = (props) => {

    const PAGE_TITLE = "Add/Edit ISO Standard";

    const [id, setId] = React.useState(props.match.params.Id);
    const isEdit = id > 0;

    const [isRedirectIsoStandard, setIsRedirectIsoStandard] = React.useState(false);
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [messages, setMessages] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [code, setCode] = React.useState('');
  

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onTitleChange = (e) => setTitle(e.target.value);
    const onCodeChange = (e) => setCode(e.target.value);

    React.useEffect(() => {
        //TODO FetchDailyTimeSheet
        fetchData();
        document.title = PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }, []);

    //TODO use effect
    const fetchData = () => {

        getIdToken(user).then((idToken) => {
        Axios.get(Config.API_URL + "IsoStandard/GetIsoStandard/?id=" + id + "&timestamp=" + new Date().getTime(),
            {
                headers: {
                    'Authorization': 'Bearer ' + idToken
                }
            })
            .then(response => {
                setId(response.data.id);
                setTitle(response.data.title);
                setCode(response.data.code);              
            });
        });
    } 

    const addEdit = () => {

        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "IsoStandard/Add",
                {
                    Title: title,
                    Code: code,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    setIsRedirectIsoStandard(true);
                    setSaveEnabled(true);

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "IsoStandard/Edit",
                {
                    Id: id,
                    Title: title,
                    Code: code
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {                    
                    setSaveEnabled(true);
                    setIsRedirectIsoStandard(true);                                       

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);
                    setMessages(messagesList);
                });
        });
    }



    if (isRedirectIsoStandard) {
        return <Redirect to={"/IsoStandard/"}></Redirect>
    }

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">

                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fas fa-layer-group fa-fw" aria-hidden="true"></i>
                            </div>
                            {isEdit &&
                                <h6 className="mb-0">Edit ISO Standrard ({id})</h6>
                            }
                            {!isEdit &&
                                <h6 className="mb-0">Add New ISO Standrard</h6>
                            }
                        </div>

                        <div className="card-body pt-2">                      
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Title</label>
                                        <input type="text" class="form-control" name="title" onChange={onTitleChange} value={title} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Code</label>
                                        <input type="number" class="form-control" name="code" onChange={onCodeChange} value={code} 
                                                            min="0"
                                                            max="99"
                                                            maxLength="2"
/>

                                    </div>
                                </div>
                            </div>
                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={()=>{setIsRedirectIsoStandard(true)}}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )


}

export default IsoStandard_Add;