// services/api.js
import axios from 'axios';

const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const fetchUsers = async () => (await API.get('/users')).data;
export const createUser = async (user) => (await API.post('/users', user)).data;
export const updateUser = async (id, user) => (await API.put(`/users/${id}`, user)).data;
export const deleteUser = async (id) => (await API.delete(`/users/${id}`)).data;


export const fetchTimesheetDailies = async (idToken, { skip = 0, take = 10, sort = [] }) => {
    let sortField = "";
    let sortDir = "";
  
    if (sort.length > 0) {
      sortField = sort[0].field || "";
      sortDir = sort[0].dir || "";
    }
  
    const { data } = await API.get(
      `/TimesheetMonthly/GetTimesheetMonthlyListAdmin/?Skip=${skip}&Take=${take}&SortField=${sortField}&SortDir=${sortDir}&time=${new Date().getTime()}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  
    return data;
  };

  // export const fetchTimesheetDaily = async (idToken) => {
  //   let sortField = "";
  //   let sortDir = "";
  
  //   if (sort.length > 0) {
  //     sortField = sort[0].field || "";
  //     sortDir = sort[0].dir || "";
  //   }
  
  //   const { data } = await API.get(
  //     `/TimesheetMonthly/GetTimesheetMonthlyListAdmin/?Skip=${skip}&Take=${take}&SortField=${sortField}&SortDir=${sortDir}&time=${new Date().getTime()}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${idToken}`,
  //       },
  //     }
  //   );
  
  //   return data;
  // };