import React from "react";
import axios from "axios";
import Config from '../../Constants/index'
import { getAuth, onAuthStateChanged, getIdToken, debugErrorMap } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import AddIsoCertCommentsForm from "./AddIsoCertCommentsForm";

const HistoryList = (props) => {

    const item = props.IsoCert;
    const [historyList, setHistoryList] = React.useState([]);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    React.useEffect(() => {
        fetchData();
    }, [])

    React.useEffect(() => {
        setHistoryList([]);
        fetchData();
    }, [props.acdCert])

    const fetchData = () => {


        getIdToken(user).then((idToken) => {
            axios.get(Config.API_URL + "AcdCertHistory/GetAcdCertHistoryList/?acdCertRef=" + item.id
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {
                setHistoryList(response.data);
                debugger;
            }).catch(error => {
                console.log(error);
            })
        });
    }

    const onSentComment = () => {
        fetchData();
    }
    

    return (
        <>
        
            <hr className="horizontal dark my-1" />


            {/* <div className="card card-plain h-100"> */}
            {/* <div className="card-header pb-0 p-3"> */}
            <h6 className="mb-0">History / Comments</h6>
            
            <AddIsoCertCommentsForm entityId={item.id} user={user} onSent={onSentComment}></AddIsoCertCommentsForm>
        

            {/* </div> */}
            <div className="card-body p-3">
                <ul className="list-group">
                    {
                        historyList.map((item) => {

                            return (<li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                                <div className="d-flex align-items-start flex-column justify-content-center">
                                    <h6 className="mb-0 text-sm">{item.createBy
                                        .replace("@citadel-engineering.com", "")
                                        .replace("@cec.ltd", "")} </h6>
                                    <p className="mb-0 text-xs">{item.memo} ({item.vCreateDateTime})</p>
                                </div>
                            </li>)

                        })
                    }
                    <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                        <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">{item.createBy
                                .replace("@citadel-engineering.com", "")
                                .replace("@cec.ltd", "")} </h6>
                            <p className="mb-0 text-xs">Create ({item.vCreateDateTime})</p>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default HistoryList;