import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert.js';
import CustomerLkp from '../../Component/CustomerLkp.jsx';
import EquipmentLkp from '../../Component/EquipmentLkp.jsx';
import UserLkp from '../../Component/UserLkp.jsx';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer.js';
import moment from 'moment';
import NavBar from '../../Navigation/NavBar.jsx';
import Footer from '../../Navigation/Footer.jsx';
import CurrentDefaultUser from '../../Component/User/CurrentDefaultUser.js'
import IsoStandardLkp from '../../Component/Iso/IsoStandardLkp.js';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
//import { it } from 'date-fns/locale';

const IsoCert_Add = (props) => {

    const PAGE_TITLE =  "Iso Certificate";

    const [id, setId] = React.useState(props.match.params.Id);
    const isEdit = id > 0;


    const [isRedirectIsoCert, setIsRedirectIsoCert] = React.useState(false);
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [messages, setMessages] = React.useState([]);
    const [clientTitle, setClientTitle] = React.useState('');
    const [clientAddress, setClientAddress] = React.useState('');
    const [clientScope, setClientScope] = React.useState('');
    const [dateOfIssue, setDateOfIssue] = React.useState('');
    const [dateOfExp, setDateOfExp] = React.useState('');
    const [isoStandardRef, setIsoStandardRef] = React.useState(0);
    const [vIsoStandardTitle, setVIsoStandardTitle] = React.useState('');    
    const [certificateNumber, setCertificateNumber] = React.useState('');

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const clientTitleHandler = (e) => setClientTitle(e.target.value);
    const clientAddressHandler = (e) => setClientAddress(e.target.value);
    const clientScopeHandler = (e) => setClientScope(e.target.value);
    const dateOfIssueHandler = (e) => setDateOfIssue(e.target.value);
    const dateOfExpHandler = (e) => setDateOfExp(e.target.value);


    const IsoStandardLkpHandler = (selectedValue) => {
        if (selectedValue) {
            setVIsoStandardTitle(selectedValue.label);
            setIsoStandardRef(selectedValue.value);
        }
        else {
            setVIsoStandardTitle("");
            setIsoStandardRef(0);
        }
    }




    React.useEffect(() => {
        if (isEdit)
            fetchData();

        if (isEdit)
            document.title = "Edit " + PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;

        if (!isEdit)
           document.title = "Add New " + PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;

    }, []);

    const fetchData = () => {

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "IsoCert/GetIsoCert/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    var item = response.data;
                    setId(item.id);
                    setCertificateNumber(item.certificateNumber);
                    setClientTitle(item.clientTitle)
                    setClientAddress(item.clientAddress);
                    setClientScope(item.clientScope);
                    setDateOfIssue(item.dateOfIssue.replace("T00:00:00", ''));
                    setDateOfExp(item.dateOfExp.replace("T00:00:00", ''));      
                    debugger;              
                    setIsoStandardRef(item.isoStandardRef);
                    fetchIsoStandard(item.isoStandardRef)
                });
        });
    }

    const fetchIsoStandard = (isoStandardRef) => {
        debugger;
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "IsoStandard/GetIsoStandard/?id=" + isoStandardRef + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    var item = response.data;
                    setVIsoStandardTitle(item.title)
                });
        });
    }


    const addEdit = () => {

        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "IsoCert/Add",
                {
                    ClientTitle: clientTitle,
                    ClientAddress: clientAddress,
                    clientScope: clientScope,
                    DateOfIssue: dateOfIssue == "" ? '1900-01-01' : dateOfIssue,
                    DateOfExp: dateOfExp == "" ? '1900-01-01' : dateOfExp,
                    IsoStandardRef: isoStandardRef                    
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    setIsRedirectIsoCert(true);
                    setSaveEnabled(true);

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            debugger;
            Axios.post(Config.API_URL + "IsoCert/Edit",
                {
                    Id: id,
                    ClientTitle: clientTitle,
                    ClientAddress: clientAddress,
                    ClientScope: clientScope,
                    DateOfIssue: dateOfIssue == "" ? '1900-01-01' : dateOfIssue,
                    DateOfExp: dateOfExp == "" ? '1900-01-01' : dateOfExp,
                    IsoStandardRef: isoStandardRef,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    setSaveEnabled(true);
                    setIsRedirectIsoCert(true);
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }


    if (isRedirectIsoCert)
        return <Redirect to={"/IsoCert/"}></Redirect>

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">

                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fas fa-certificate fa-fw" aria-hidden="true"></i>
                            </div>
                            
                            {isEdit &&
                                <h6 className="mb-0">Edit {PAGE_TITLE + " " + certificateNumber}</h6>
                            }
                            {!isEdit &&
                                <h6 className="mb-0">Add New {PAGE_TITLE + " " + certificateNumber}</h6>
                            }


                        </div>

                        <div className="card-body pt-2">
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Client Title</label>
                                        <input type="text" class="form-control" name="clientTitle" onChange={clientTitleHandler}
                                            value={clientTitle} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Client Address</label>
                                        <input type="text" class="form-control" name="clientAddress" onChange={clientAddressHandler}
                                            value={clientAddress} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Client Scope</label>
                                        <input type="text" class="form-control" name="clientScope" onChange={clientScopeHandler}
                                            value={clientScope} />
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Issue Date</label>
                                        <input type="date" class="form-control" name="dateOfIssue" onChange={dateOfIssueHandler} value={dateOfIssue} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Expiration Date</label>
                                        <input type="date" class="form-control" name="dateOfExp" onChange={dateOfExpHandler} value={dateOfExp} />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Iso Standard</label>
                                        <IsoStandardLkp name="isoStandardRef" user={user} onChange={IsoStandardLkpHandler}
                                            defaultValue={{ label: vIsoStandardTitle, value: isoStandardRef }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={() => { setIsRedirectIsoCert(true) }}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )
}

export default IsoCert_Add;
